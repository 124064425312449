//React related imports...
import React, { useReducer } from 'react';
import JSONTree from 'react-json-tree';

/**
 * @name JSONFormatter
 * @param {object} props props
 * @summary This component displays the FTP FileContent data.
 * @returns {object} React.Fragement that encapsulated with FileContent details.
 */
const JSONFormatter = props => {

    /**
     * @name objTheme
     * @summary Sample style object to be customized later
     */
    const objTheme = {
        scheme: 'monokai',
        author: 'wimer hazenberg (http://www.monokai.nl)',
        base00: '#272822',
        base01: '#383830',
        base02: '#49483e',
        base03: '#75715e',
        base04: '#a59f85',
        base05: '#f8f8f2',
        base06: '#f5f4f1',
        base07: '#f9f8f5',
        base08: '#f92672',
        base09: '#fd971f',
        base0A: '#f4bf75',
        base0B: '#a6e22e',
        base0C: '#a1efe4',
        base0D: '#66d9ef',
        base0E: '#ae81ff',
        base0F: '#cc6633'
    };

    /**
     * @name GetContent
     * @summary Forms the whole jsx required for the module.
     * @returns {object} jsx
     */
    const GetContent = () => {
        return < JSONTree
                 data={props.Data.JSONData}
                 theme={objTheme}
                 hideRoot={true}
                 shouldExpandNode={()=> props.Expand?true:false}
                />
    }

    return (
        <React.Fragment>{props.Data.JSONData ?
            <React.Fragment>{GetContent()}</React.Fragment> : <React.Fragment />}
        </React.Fragment>
    );
}

export default JSONFormatter;